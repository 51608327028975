@use 'sass:math';

$colors: (
  color-primary-light: rgb(255, 255, 255),
  color-primary-light-customized: rgb(249, 251, 255),
  color-primary-dark: rgb(74, 92, 115),
  color-primary-accent: rgb(96, 190, 179),
  color-secondary: rgb(216, 217, 223),
  color-shadow: rgba(74, 92, 115, 0.18),
  color-transparent: transparent,
  color-valid-secondary: #d0e9c6,
  color-invalid-primary: rgb(253, 0, 0),
  color-invalid-secondary: #f2dede,
);

$h-1: 2.5rem;
$h-2: 2rem;
$h-3: 1.75rem;
$h-4: 1.5rem;
$h-5: 1.25rem;
$h-6: 1rem;

$d-1: 6rem;
$d-2: 5.5rem;
$d-3: 4rem;
$d-4: 3.5rem;

$text-xxs: 0.9rem;
$text-xs: $h-6;
$text-s: $h-4;
$text-m: $h-2;
$text-l: $d-4;
$text-xl: $d-2;

$container-padding: 3em 2em;

$transition-duration: 0.3s;
$animation-duration: 2s;
$animation-delay: 0.5s;

$CENTRAL-ANGLE: 60;
$ANCHOR-SKEW: 30deg;

$thickness: 1.5rem;
$border-length: 35%;

$media-query-max-width: 1600px;
$ROOT-FONT-SIZE: pixelToRem(16);

$border-base-color: map-get($colors, color-primary-light-customized) 90deg,
  map-get($colors, color-primary-dark) 0;

$border-base-color-light: map-get($colors, color-primary-light) 90deg,
  map-get($colors, color-primary-dark) 0;
