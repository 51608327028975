@use './variables' as *;
@use './functions' as *;


@mixin setAnimationTiming($X, $Y, $C, $Z) {
  animation-timing-function: cubic-bezier($X, $Y, $C, $Z);
}

@mixin setBoxShadow($offsetX, $offsetY, $blurRadius, $spreadRadius, $color) {
  box-shadow: pixelToRem($offsetX) pixelToRem($offsetY) pixelToRem($blurRadius)
    pixelToRem($spreadRadius) $color;
}
