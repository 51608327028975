@use './app/scss/abstracts/' as *;
@use './app/scss/layout' as *;

/* Open Sans Regular  */

@font-face {
  font-family: 'Open Sans';
  src: url(assets/fonts/OpenSans/OpenSans-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

/* Open Sans Bold  */

@font-face {
  font-family: 'Open Sans Bold';
  src: url(assets/fonts/OpenSans/OpenSans-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

/* Open Sans Italic  */

@font-face {
  font-family: 'Open Sans Italic';
  src: url(assets/fonts/OpenSans/OpenSans-Italic.ttf);
  font-weight: normal;
  font-style: italic;
}

/* Open Sans Bold Italic  */

@font-face {
  font-family: 'Open Sans Bold Italic';
  src: url(assets/fonts/OpenSans/OpenSans-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}

body,
html {
  font-family: 'Open Sans', Arial;
  font-size: $ROOT-FONT-SIZE;
  border: 0;
  margin: 0;
  padding: 0;
}

.fc .fc-view-harness-active > .fc-view {
  background-color: map-get($colors, color-primary-dark);
  border: 0 !important;
  color: map-get($colors, color-primary-light);
  @include setBoxShadow(7, 5, 14, 0, map-get($colors, color-shadow));
  border-radius: 0.4rem;
}

.fc-scrollgrid .fc-scrollgrid-liquid {
  border: 0 !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: map-get($colors, color-primary-dark) !important;
}

.fc .fc-daygrid-day-frame:hover {
  background-color: map-get($colors, color-primary-accent) !important;
  transition: $transition-duration;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child),
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  background-color: map-get($colors, color-primary-dark) !important;
  transition: $transition-duration;
  border: 0;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(n):hover {
  background-color: map-get($colors, color-primary-accent) !important;
}

.fc .fc-daygrid-day-frame {
  height: pixelToRem(42);
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5rem !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 0 !important;
}

.active-day {
  background-color: map-get($colors, color-primary-accent) !important;
}

.inactive-day {
  background-color: map-get($colors, color-primary-dark) !important;
}

.fc-scroller {
  overflow: hidden !important;
}

.fc-scroller-liquid-absolute {
  overflow: hidden !important;
}

.fc-scroller-harness {
  min-height: pixelToRem(40);
}

.disabled-button {
  opacity: 0.3;
  pointer-events: none;
}

.fc .fc-col-header-cell-cushion {
  font-weight: lighter !important;
  margin-top: 0.7rem;
}

.material-icons {
  color: map-get($colors, color-primary-dark);
}

.material-icons--active {
  color: map-get($colors, color-primary-accent);
}

.fc .fc-toolbar-title {
  color: map-get($colors, color-primary-dark);
  font-weight: lighter;
  font-size: $text-xs !important;
}

.invalid {
  background-color: map-get($colors, color-invalid-secondary) !important;
  color: map-get($colors, color-invalid-primary) !important;
}

.valid {
  background-color: map-get($colors, color-valid-secondary) !important;
  color: map-get($colors, color-primary-accent);
}

.date-blocked {
  cursor: not-allowed !important;
  background-color: map-get($colors,  color-primary-accent);

}
