@use '../abstracts/variables' as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}

.col-1 {
  width: calc(100% / 6);
}

.col-2 {
  width: calc(100% / 6 * 2);
}

.col-3 {
  width: calc(100% / 6 * 3);
}

.col-4 {
  width: calc(100% / 6 * 4);
}

.col-5 {
  width: calc(100% / 6 * 5);
}

.col-6 {
  width: calc(100% / 6 * 6);
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6 {
  padding: 2em 1em;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.container-fluid {
  width: 100%;
}

@media only screen and (max-width: $media-query-max-width) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6 {
    width: 100%;
  }

  #ringIconUpperLeft,
  #ringIconLowerRight {
    display: none;
  }

  .row,
  .date-content,
  .pdf-col,
  .dashboard-col__header {
    flex-flow: wrap;
  }

  .log-in {
    &__icon-pdf {
      width: 100%;
    }

    &__content {
      margin-top: 10rem;
    }
  }

  .dashboard-col {
    &__header {
      flex-flow: wrap;
    }
  }

  .radial-menu {
    &__wrapper {
      transform: scale(0.7) !important;
    }
  }
}
